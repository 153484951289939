import 'leaflet-editable';
import { Box, Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'oskcomponents';
import React, { useEffect, useState } from 'react';
import { getMapboxApiKey } from '~/utils';

export type BaseMapAttributionModalType = {
    /** Whether or not this modal is visible. */
    visible?: boolean;
    /** A method that's called when the modal is dismissed. */
    onClose?: () => void;
};

type AttributionDisplay = {
    /** A url to use when linking attribution */
    url: string;
    /** The label we've retrieved from the attribution endpoint */
    label: string;
};

export const BaseMapAttributionModal = ({ visible = false, onClose }: BaseMapAttributionModalType) => {
    const [attributions, setAttributions] = useState<Array<AttributionDisplay>>([]);

    useEffect(() => {
        /*
            MapBox's attribution information is stored in their tileset metadata. We need to retrieve the list of attributions
            from their tileset endpoint and parse it out for friendly display.

            NOTE: If we ever point at a different tile source, we'll need to update the `mapbox.satellite` id in the attribution url
        */
        const mapboxApiKey = getMapboxApiKey();
        const SatelliteAttributionUrl = `https://api.mapbox.com/v4/mapbox.satellite.json?access_token=${mapboxApiKey}`;
        const attrRegex = /\\?"(http.*?)\\?" .*?>(.*?)</gim;

        fetch(SatelliteAttributionUrl)
            .then((response) => response.json())
            .then((body) => {
                /*
                    Because we're loading some markup from this endpoint, we'll want to make sure that we
                    aren't loading anything suspicious. Throw an exception that we can track in Sentry and
                    explode the impending call if we detect anything weird.
                */
                if (body.attribution.includes('<script')) {
                    const errorMessage =
                        'Critical security alert, <script> tag detected in body of response from mapbox API. ';

                    console.error(errorMessage, body);

                    throw Error(errorMessage + body);
                } else {
                    // @ts-ignore
                    const regexResult = [...(body.attribution as string).matchAll(attrRegex)];

                    setAttributions(
                        regexResult.map((match) => ({
                            url: match[1],
                            label: match[2],
                        })),
                    );
                }
            });
    }, []);

    const attrHtml = attributions
        .map(
            (attribution, idx) =>
                `<a key='attr-${idx}' target='_blank' href='${attribution.url}'>
            ${attribution.label}
        </a>`,
        )
        .join('');

    return (
        <Modal visible={visible}>
            <ModalHeader variant="primary">Data Attribution</ModalHeader>
            <ModalBody>
                <Box col grow>
                    <Typography strong variant="body2">
                        The current map view includes data from:
                    </Typography>
                    <Typography variant="heading5">
                        <div
                            dangerouslySetInnerHTML={{ __html: attrHtml }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        />
                    </Typography>
                </Box>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onClose && onClose()}>Done</Button>
            </ModalFooter>
        </Modal>
    );
};
