import { Box, Form, Heading, ListEntry, OSKThemeType, Spinner, Text, TextInput, Typography } from 'oskcomponents';
import {
    DownloadIntent,
    EULADocument,
    noop,
    OrderRequest,
    OrderRequestOrderTypeEnum,
    OSKGeoJson,
    PaginatedEULADocumentList,
    SigmaAPI,
    useProfileMetadata,
} from 'oskcore';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CartCustomization } from '~/molecules';
import { emptyCart, getVisibleItems } from '~/redux/modules/data/cart';
import { RootState } from '~/redux/store';
import { CartWizardStep, CartWizardTemplate } from '~/templates';
import { ArchiveSummarySection } from './ArchiveSummarySection';
import * as yup from 'yup';
import { getProgramId, parseS3, user_fullname } from '~/utils';
import { setActiveOrderId, setActiveOrderType } from '~/redux/modules/data/orders';
import FileTypePicker from '~/organisms/FileTypePicker';
import ProductPicker from '~/organisms/ProductPicker';
import EulaPicker from '~/organisms/EulaPicker';
import { getFootprints } from '~/redux/modules/data/map';
import { clearOverlayModes } from '~/redux/modules/data/search';
import { useToggles } from '~/hooks/useToggles';

export type FinalizeArchiveCheckoutProps = {
    /** All items added to the cart */
    enqueuedItems?: Record<string, DownloadIntent | undefined>;
    /** The visible items in the cart. */
    checkoutItems?: Array<DownloadIntent | undefined>;
    /** The footprints that are visible on the map, used for logging */
    visibleFootprints?: OSKGeoJson[];
    /** The search aoi that's visible on the map, used for logging */
    visibleAoi?: any;
} & CartWizardStep;

export const PlaceOrderSchema: yup.SchemaOf<any> = yup.object().shape({
    destination: yup.string().required('Destination is a required field'),
    product_level: yup.string().required('Product Level is a required field'),
    file_type: yup.string().required('File Type is a required field'),
});

const BUCKET_STORAGE_ITEM = 'BUCKET_STORAGE_ITEM';
const SELECTED_PRODUCT_STORAGE_ITEM = 'SELECTED_PRODUCT_STORAGE_ITEM';
const SELECTED_FILE_TYPE_STORAGE_ITEM = 'SELECTED_FILE_TYPE_STORAGE_ITEM';
const SELECTED_EULA_STORAGE_ITEM = 'SELECTED_EULA_STORAGE_ITEM';

export const FinalizeArchiveCheckout = ({
    enqueuedItems,
    checkoutItems,
    onWizardBack,
    onWizardNext,
    visibleFootprints,
    visibleAoi,
}: FinalizeArchiveCheckoutProps) => {
    // Parse localstorage goodies
    const defaultBucket = localStorage.getItem(BUCKET_STORAGE_ITEM);
    const defaultProduct = localStorage.getItem(SELECTED_PRODUCT_STORAGE_ITEM) ?? undefined;
    const defaultEula = localStorage.getItem(SELECTED_EULA_STORAGE_ITEM) ?? undefined;
    const defaultFileTypeString = localStorage.getItem(SELECTED_FILE_TYPE_STORAGE_ITEM) ?? null;
    let defaultFileTypeOption = null;

    try {
        defaultFileTypeOption = defaultFileTypeString ? JSON.parse(defaultFileTypeString) : null;
    } catch (ex) {
        localStorage.removeItem(SELECTED_FILE_TYPE_STORAGE_ITEM);
    }

    const theme = useTheme() as OSKThemeType;
    const programId = getProgramId();
    const dispatch = useDispatch();
    const toggle = useToggles();
    const [user] = useProfileMetadata();
    const orderNameRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState(null);
    const [eulaError, setEulaError] = useState<string>();
    const [bucket, setBucket] = useState<string | null>(defaultBucket);
    const [selectedProducts, setSelectedProducts] = useState<string | undefined>(defaultProduct);
    const [selectedFileTypes, setSelectedFileTypes] = useState<ListEntry | null>(defaultFileTypeOption);
    const [selectedEula, setSelectedEula] = useState<string | undefined>(defaultEula);
    const [submitting, setSubmitting] = useState(false);
    const [isLoadingEulas, setIsLoadingEulas] = useState<boolean>(false);
    const [eulaDocuments, setEulaDocuments] = useState<EULADocument[]>([]);

    useEffect(() => {
        setIsLoadingEulas(true);
        SigmaAPI.listEulas({ program: programId })
            .then((response) => response.data)
            .then((data: PaginatedEULADocumentList) => {
                setIsLoadingEulas(false);

                if (data.results && data.count > 0) {
                    setEulaDocuments(data.results);
                }
            })
            .catch((err) => {
                setEulaError(err);
                setIsLoadingEulas(false);
            });
    }, []);

    // Only show / use the EULA option for ENVI files
    const ShouldShowEULA =
        selectedFileTypes?.value === 'ENVI' && (isLoadingEulas ? true : eulaDocuments.length > 0 || eulaError);

    useEffect(() => {
        if (orderNameRef && orderNameRef.current) {
            orderNameRef.current.focus();
        }
    }, [orderNameRef]);

    return (
        <Form
            onSubmit={(data: any) => {
                setSubmitting(true);
                setError(null);
                const { s3Bucket, s3Folder } = parseS3(data.destination);
                const orderRequest: OrderRequest = {
                    name: data?.order_name,
                    orderType: OrderRequestOrderTypeEnum.Archive,
                    customerPoc: user_fullname(user),
                    inventoryId: checkoutItems?.flatMap((item) => (item ? item.fileId : [])),
                    s3Bucket,
                    s3Folder,
                    fileFormat: selectedFileTypes?.value,
                    // @ts-ignore
                    productLevel: selectedProducts,
                    ...(ShouldShowEULA && !eulaError && selectedEula && { requiredLicense: selectedEula }),
                };

                SigmaAPI.createOrder({
                    orderRequest,
                    program: getProgramId(),
                })
                    .then((order) => {
                        const orderId = order.data.id;
                        if (orderId) {
                            dispatch(setActiveOrderId(orderId));
                            dispatch(setActiveOrderType('archive'));
                        }

                        // Log some order info for Sentry to pick up
                        console.info('archive order info', {
                            orderName: orderRequest.name,
                            cartItems: JSON.stringify(enqueuedItems),
                            checkoutItems: JSON.stringify(orderRequest.inventoryId),
                            orderItems: JSON.stringify(order.data.inventoryId),
                            visibleAoi: JSON.stringify(visibleAoi),
                            visibleFootprints: visibleFootprints?.map((footprint) =>
                                JSON.stringify(footprint.toGeoJSON()),
                            ),
                        });

                        onWizardNext && onWizardNext();

                        // Empty the cart and clear overlay modes on success
                        dispatch(emptyCart());
                        dispatch(clearOverlayModes());
                    })
                    .catch((ex) => {
                        console.error(ex);
                        setError(ex.toString());
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            schema={PlaceOrderSchema}
            style={{ flexDirection: 'row', flexGrow: 1, display: 'flex', width: '100%' }}
        >
            <CartWizardTemplate
                buttons={[
                    { variant: 'primary', inverted: true, label: 'Back', onClick: onWizardBack ?? noop },
                    {
                        variant: 'action',
                        noborder: true,
                        label: 'Place Order',
                        submit: true,
                        onClick: noop,
                    },
                ]}
                style={{
                    width: '100%',
                    overflowY: 'hidden',
                }}
            >
                <Box style={{ width: '100%', overflowY: 'hidden', height: '100%' }} col>
                    <Box center="all" pt={40}>
                        {error && <Text color={theme.colors.red}>{error}</Text>}
                    </Box>
                    <Box pt={0} style={{ overflowY: 'auto' }} grow center="horizontal">
                        {submitting && <Spinner style={{ marginTop: '200px' }} size="Large" variant="Box" />}

                        <Box
                            style={{
                                maxWidth: '1000px',
                                height: '100%',
                                display: submitting ? 'none' : 'flex',
                            }}
                            grow
                        >
                            <Box fg={theme.colors.black900} style={{ height: '770px', maxWidth: '55%' }} col grow>
                                <CartCustomization title={'Order Name'}>
                                    <Box pb={10} col>
                                        <Text variant="small">Assign a name for this order.</Text>
                                    </Box>
                                    <TextInput inverted name="order_name" placeholder="Order Name" ref={orderNameRef} />
                                </CartCustomization>
                                <CartCustomization title={'Destination'}>
                                    <Box pb={10} col>
                                        <Text variant="small">Select the destination bucket for file delivery.</Text>
                                        <Text variant="small">This should be a fully qualified s3 uri.</Text>
                                    </Box>
                                    <TextInput
                                        inverted
                                        value={bucket}
                                        onChange={(evt) => {
                                            setBucket(evt.target.value);
                                            localStorage.setItem(BUCKET_STORAGE_ITEM, evt.target.value);
                                        }}
                                        placeholder="s3://bucketname.com/some/folder"
                                        name="destination"
                                    />
                                </CartCustomization>
                                <CartCustomization title={'Product Level(s)'}>
                                    <Box pb={10} col>
                                        <Text variant="small">Select which products you are ordering.</Text>
                                    </Box>
                                    <ProductPicker
                                        defaultValue={selectedProducts}
                                        onChange={(product) => {
                                            setSelectedProducts(product);
                                            localStorage.setItem(SELECTED_PRODUCT_STORAGE_ITEM, product);
                                        }}
                                    />
                                </CartCustomization>
                                <CartCustomization title={'File Type'}>
                                    <Box pb={10} col>
                                        <Text variant="small">
                                            Select the file types that you want data delivered as.
                                        </Text>
                                    </Box>
                                    <Box col>
                                        <FileTypePicker
                                            defaultValue={defaultFileTypeOption}
                                            selectedProduct={selectedProducts}
                                            onChange={(option) => {
                                                setSelectedFileTypes(option);
                                                localStorage.setItem(
                                                    SELECTED_FILE_TYPE_STORAGE_ITEM,
                                                    JSON.stringify(option),
                                                );
                                            }}
                                        />
                                    </Box>
                                </CartCustomization>
                                {ShouldShowEULA &&
                                    (isLoadingEulas ? (
                                        <Box style={{ width: '100%' }} center="all">
                                            <Spinner size="Tiny" variant="Box" />
                                        </Box>
                                    ) : (
                                        <CartCustomization title={'End User License Agreement'}>
                                            <Box pb={10} col>
                                                <Text variant="small">
                                                    Select the EULA type that you want delivered. (This will also be set
                                                    in the ENVI headers.)
                                                </Text>
                                            </Box>
                                            <Box col>
                                                {eulaError ? (
                                                    <Typography variant="body3" strong color="red">
                                                        {eulaError.toString()}
                                                    </Typography>
                                                ) : (
                                                    <EulaPicker
                                                        defaultValue={defaultEula}
                                                        eulaDocuments={eulaDocuments}
                                                        onChange={(id) => {
                                                            setSelectedEula(id);
                                                            localStorage.setItem(SELECTED_EULA_STORAGE_ITEM, id);
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </CartCustomization>
                                    ))}
                            </Box>
                            <Box ml={40} col>
                                <Box
                                    w={400}
                                    p={40}
                                    style={{
                                        borderRadius: '8px',
                                        lineHeight: '3rem',
                                        position: 'fixed',
                                    }}
                                    bg={theme.colors.black900}
                                    fg={theme.colors.white}
                                    col
                                >
                                    <Heading>Summary</Heading>
                                    <ArchiveSummarySection>
                                        <Box center="vertical" col>
                                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>Destination Bucket</Text>
                                            <Text>{bucket ?? 'None'}</Text>
                                        </Box>
                                    </ArchiveSummarySection>
                                    <ArchiveSummarySection>
                                        <Box center="vertical">
                                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>Data Level</Text>
                                            <Text>{selectedProducts ? selectedProducts : 'None'}</Text>
                                        </Box>
                                    </ArchiveSummarySection>
                                    <ArchiveSummarySection>
                                        <Box center="vertical">
                                            <Text style={{ fontWeight: 'bold', flexGrow: 1 }}>File Type</Text>
                                            <Text>{selectedFileTypes?.label ?? 'None'}</Text>
                                        </Box>
                                    </ArchiveSummarySection>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CartWizardTemplate>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        enqueuedItems: state.data.cart.enqueued, // All items in the cart
        checkoutItems: getVisibleItems(state), // Only items that were selected for final checkout
        visibleFootprints: getFootprints(state),
        visibleAoi: state.data.search.roi,
    };
};

export default connect(mapStateToProps, noop)(FinalizeArchiveCheckout);
