import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, OSKThemeType } from 'oskcomponents';
import { LatLng, Point } from 'leaflet';
import { useMap } from 'react-leaflet';
import { useTheme } from 'styled-components';
import { useDisableFeatureOnMouseOver } from '~/hooks';
import { CopyCoordinateMenuOption } from './ContextMenuOptions';
import { GlobalZIndex } from '~/constants';

type MapContextMenuProps = {
    /** The target position on the map */
    position?: LatLng;
};

const MapContextMenu = ({ position }: MapContextMenuProps) => {
    const map = useMap();
    const theme = useTheme() as OSKThemeType;
    const ref = useRef(null);

    const [showContextMenu, setShowContextMenu] = useState<boolean>(position !== undefined);
    const [shouldHideContextMenuOnMouseDown, setShouldHideContextMenuOnMouseDown] = useState<boolean>(true);
    const [containerPosition, setContainerPosition] = useState<Point>();

    const options = useMemo(() => [CopyCoordinateMenuOption], []);

    useDisableFeatureOnMouseOver(ref, 'Drag', showContextMenu);
    useDisableFeatureOnMouseOver(ref, 'Zoom', showContextMenu);

    const HideContextMenuOnMouseDown = useCallback(() => {
        if (shouldHideContextMenuOnMouseDown) {
            setShowContextMenu(false);
        }
    }, [shouldHideContextMenuOnMouseDown]);

    const HideContextMenuOnZoom = () => setShowContextMenu(false);

    useEffect(() => {
        const { canClick } = map.options as any;
        if (position && canClick) {
            const point = map.latLngToContainerPoint(position);
            setContainerPosition(point);
            setShowContextMenu(true);
            setShouldHideContextMenuOnMouseDown(true);
        } else {
            setShowContextMenu(false);
        }
    }, [position]);

    useEffect(() => {
        window.addEventListener('mousedown', HideContextMenuOnMouseDown);

        return () => {
            window.removeEventListener('mousedown', HideContextMenuOnMouseDown);
        };
    }, [shouldHideContextMenuOnMouseDown]);

    useEffect(() => {
        map.addEventListener('zoomstart', HideContextMenuOnZoom);

        return () => {
            map.removeEventListener('zoomstart', HideContextMenuOnZoom);
        };
    }, []);

    return (
        <Box
            id="osk-context-menu"
            ref={ref}
            style={{
                display: showContextMenu ? 'inherit' : 'none',
                position: 'absolute',
                left: `${containerPosition?.x}px`,
                top: `${containerPosition?.y}px`,
                zIndex: GlobalZIndex.ActionBar,
            }}
            onMouseEnter={() => setShouldHideContextMenuOnMouseDown(false)}
            onMouseLeave={() => setShouldHideContextMenuOnMouseDown(true)}
        >
            <Box
                style={{
                    backgroundColor: 'white',
                    padding: '2px',
                    borderRadius: '5px',
                    position: 'fixed',
                    zIndex: '10',
                }}
                col
            >
                {options.map((option, idx) => (
                    <div key={`option-${idx}`}>
                        {option({
                            latlng: position,
                            onClick: (e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                // Hide the context menu
                                setShowContextMenu(false);
                                setShouldHideContextMenuOnMouseDown(true);
                            },
                        })}
                    </div>
                ))}
            </Box>

            <Box
                style={{
                    position: 'absolute',
                    width: '20px',
                    height: '20px',
                    backgroundColor: theme.colors.accent,
                    top: '-10px',
                    left: '-10px',
                    outline: `3px solid ${theme.colors.accent}`,
                    outlineOffset: '4px',
                    borderRadius: '100px',
                }}
            />
        </Box>
    );
};

export { MapContextMenu };
export type { MapContextMenuProps };
