import { ListEntry, OSKThemeType, Select, SelectProps, Typography } from 'oskcomponents';
import { EULADocument } from 'oskcore';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

export type EulaTypePickerProps = {
    /** The method to invoke when the selection changes */
    onChange: (option: string) => void;
    /** A list of eula types that the user can <select name="" id="" className=""></select> */
    eulaDocuments: EULADocument[];
    /** The default entry to pick */
    defaultValue?: string;
} & Omit<Omit<Omit<Omit<Omit<SelectProps, 'name'>, 'items'>, 'variant'>, 'inverted'>, 'onChange'>;

export const EulaPicker = ({ onChange, eulaDocuments, defaultValue, ...props }: EulaTypePickerProps) => {
    const theme = useTheme() as OSKThemeType;

    const eulaTypeOptions: Array<ListEntry> = [
        {
            id: -1,
        } as EULADocument,
        ...eulaDocuments,
    ].map((eula) => ({
        label:
            eula.name || eula.short_name ? (
                <div>
                    <b>{eula.short_name}</b>
                    {eula.name && ' -'}
                    <Typography variant="body4" as="span" color={theme.colors.gray80} style={{ paddingLeft: '4px' }}>
                        {eula.name}
                    </Typography>
                </div>
            ) : (
                'No License'
            ),
        value: eula.identifying_value,
    }));

    // Derive the default option
    const defaultEulaType = defaultValue ? defaultValue : eulaTypeOptions[0];
    const [selectedEulaType, setSelectedEulaType] = useState<string>(defaultEulaType);

    useEffect(() => {
        // Validate the default, just in case something
        // old has been stuck in localStorage.
        let valid = false;
        for (const option of eulaTypeOptions) {
            if (option.value === defaultEulaType) {
                valid = true;
            }
        }

        if (!valid) {
            setSelectedEulaType('');
        }
    }, []);

    useEffect(() => {
        onChange && onChange(selectedEulaType);
    }, [selectedEulaType, onChange]);

    return (
        <Select
            name="eula_type"
            inverted
            variant="primary"
            items={eulaTypeOptions}
            defaultValue={selectedEulaType}
            placeholder="No License"
            onSelect={(option) => {
                if (option) {
                    setSelectedEulaType(option.value);
                }
            }}
            {...props}
        />
    );
};

export default EulaPicker;
